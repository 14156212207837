import axios from "axios";
import { z } from "zod";
import { Application, RankingItem } from "../models";
import utils from "../utils";

const BASE_URL = process.env.REACT_APP_QV_API_BASE_URL || "http://localhost:8080";

const RankingStatsPayload = z.object({
  userMetadata: z.any(),
  firstGame: z.string().optional(),
  lastGame: z.string().optional(),
  numberOfGames: z.number().optional(),
  totalScore: z.number().optional(),
  ranking: z.object({
    position: z.number(),
    numberOfPlayers: z.number(),
  }),
});

type RankingStats = z.infer<typeof RankingStatsPayload>;

export const getApplicationRaking = async (applicationId: string): Promise<RankingItem[]> => {
  try {
    const { data } = await axios({
      headers: { "application-id": applicationId },
      method: "get",
      url: `${BASE_URL}/application/stats`,
    });

    RankingStatsPayload.array().parse(data);

    // Safe type inferring after parse
    const validData = data as RankingStats[];

    return validData.map((ranking) => ({
      userMetadata: ranking.userMetadata,
      firstGame: ranking.firstGame && utils.dateString2Local(ranking.firstGame),
      lastGame: ranking.lastGame && utils.dateString2Local(ranking.lastGame),
      numberOfGames: ranking.numberOfGames || 0,
      totalScore: ranking.totalScore || 0,
      ranking: ranking.ranking.position,
    }));
  } catch (error) {
    console.error("Error while retrieving stats", error);

    return [];
  }
};

const ApplicationPayload = z.object({
  applicationName: z.string(),
});

type ApplicationPayloadType = z.infer<typeof ApplicationPayload>;

export const getApplication = async (applicationId: string): Promise<Application | undefined> => {
  try {
    const { data } = await axios({
      headers: { "application-id": applicationId },
      method: "get",
      url: `${BASE_URL}/application/config`,
    });

    ApplicationPayload.parse(data);

    // Safe type inferring after parse
    const validData = data as ApplicationPayloadType;

    return { id: applicationId, name: validData.applicationName };
  } catch (error) {
    console.error("Error while retrieving stats", error);

    return;
  }
};
