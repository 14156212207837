import React, { useEffect, useMemo, useState } from "react";
import { Button, Spin, Table, Typography } from "antd";
import { CSVLink } from "react-csv";
import { quizvivorService } from "../../service";
import { Application, RankingItem } from "../../models";

const { Column, ColumnGroup } = Table;
const { Title } = Typography;

type MapperKeys = "ranking" | "numberOfGames" | "totalScore" | "firstGame" | "lastGame";

type MapperValues = keyof Omit<RankingItem, "userMetadata">;

const dataIndexMapper: { [key in MapperKeys]: MapperValues } = {
  ranking: "ranking",
  numberOfGames: "numberOfGames",
  totalScore: "totalScore",
  firstGame: "firstGame",
  lastGame: "lastGame",
};

const sorter = (dataIndex: MapperValues) => (a: RankingItem, b: RankingItem) => {
  const left = a[dataIndex];
  const right = b[dataIndex];

  if (!left || !right) return 0;
  if (typeof left === "string" || typeof right === "string") return 0;

  return left - right;
};

interface props {
  applicationId: string;
}

const RankingTable: React.FC<props> = (props) => {
  const { applicationId } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [stats, setStats] = useState<RankingItem[]>([]);
  const [application, setApplication] = useState<Application>();

  const userMetadataKeys = useMemo(() => {
    const keys: string[] = [];
    stats.forEach((data) => {
      Object.keys(data.userMetadata).forEach((key) => data.userMetadata[key] && !keys.includes(key) && keys.push(key));
    });

    return keys;
  }, [stats]);

  const fetchData = async (applicationId: string) => {
    setIsLoading(true);
    const [application, stats] = await Promise.all([
      quizvivorService.getApplication(applicationId),
      quizvivorService.getApplicationRaking(applicationId),
    ]);
    setApplication(application);
    setStats(stats);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData(applicationId);
  }, [applicationId]);

  if (isLoading) return <Spin size="large" />;

  if (stats.length === 0) return null;

  return (
    <div className="ranking-container flex-col-center">
      <Title level={5}>{application?.name}</Title>
      <Button type="primary" onClick={() => fetchData(applicationId)}>
        Rafraîchir
      </Button>
      <CSVLink
        filename="classement.csv"
        data={stats.map(({ userMetadata, ...rank }) => ({ ...userMetadata, ...rank }))}
      >
        {"Télécharger les données"}
      </CSVLink>
      <Table pagination={{ defaultPageSize: 50 }} size="small" className="ranking-table" dataSource={stats}>
        <ColumnGroup title="Données utilisateur">
          {userMetadataKeys.map((key) => (
            <Column title={key} dataIndex={["userMetadata", key]} key={key} />
          ))}
        </ColumnGroup>
        <Column sorter={sorter(dataIndexMapper.ranking)} title={"Position"} dataIndex={dataIndexMapper.ranking} />
        <Column
          title={"Nombre de parties"}
          dataIndex={dataIndexMapper.numberOfGames}
          sorter={sorter(dataIndexMapper.numberOfGames)}
        />
        <Column title={"Score"} dataIndex={dataIndexMapper.totalScore} />
        <Column title={"Première partie"} dataIndex={dataIndexMapper.firstGame} />
        <Column title={"Dernière partie"} dataIndex={dataIndexMapper.lastGame} />
      </Table>
    </div>
  );
};

export default RankingTable;
